//? Definir cookie - parâmetros (nome, valor, expiração)

export const setCookie = (name, value, daysToExpire) => {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + daysToExpire)
  const cookieValue = `${encodeURIComponent(name)}=${value};expires=${expirationDate.toUTCString()};path=/; domain=.${process.env.VUE_APP_BASE_URL}`
  document.cookie = cookieValue
}

//? Pegar o valor do cookie - parâmetro (nome)

export const getCookie = (name) => {
  // Cria uma expressão regular para encontrar o cookie na string de cookies
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  // Retorna o valor do cookie se encontrado, caso contrário, retorna null
  return match ? match[2] : null
}

//? Deletar todos os cookies - sem parâmetros

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
  }
}

//? Deletar cookies específico (cookieName)

export const deleteCookie = (cookieName) => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (name === cookieName) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
      break // Sai do loop após encontrar e excluir o cookie desejado
    }
  }
}
