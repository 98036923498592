import axios from '@/utilities/axios'
import { getUser } from '@/utilities/accessControl'

export default {
  namespaced: true,
  state: {
    account_id: '',
    contract_id: '',
    unity_id: '',
    company_id: '',
    listaAccounts: [],
    listaContratos: [],
    listaUnidades: [],
    listaEmpresas: [],
    filteredUnities: [],
    filteredCompanies: []
  },
  mutations: {
    changeFilter(state, data) {
      switch (data.tipo) {
        case 'account':
          state.account_id = data.value
          break
        case 'contract':
          state.contract_id = data.value
          break
        case 'unity':
          state.unity_id = data.value
          break
        case 'company':
          state.company_id = data.value
          break
      }
    },
    LISTAGEM_ACCOUNTS(state, data) {
      state.listaAccounts = data.map((account) => ({ value: account.id, name: account.name }))
    },
    LISTAGEM_CONTRATOS(state, data) {
      state.listaContratos = data.map((contrato) => ({ value: contrato.id, name: contrato.name }))
    },
    LISTAGEM_UNIDADES(state, data) {
      state.listaUnidades = data.map((unidade) => ({
        value: { id: unidade.id, contrato_id: unidade.subgrupo_id },
        name: unidade.name
      }))
    },
    LISTAGEM_EMPRESAS(state, data) {
      state.listaEmpresas = data.map((empresa) => ({
        value: { contrato_id: empresa.subgrupo_id, unidade_id: empresa.unidade_id, id: empresa.id },
        name: empresa.name
      }))
    },
    setFilteredUnities(state, unities) {
      state.filteredUnities = unities
    },
    setFilteredCompanies(state, companies) {
      state.filteredCompanies = companies
    }
  },
  actions: {
    async LISTAR_ACCOUNTS({ commit }) {
      try {
        const response = await axios.get(`/api/admin/user/${getUser.id}`)
        commit('LISTAGEM_ACCOUNTS', response.data.user.accounts)
      } catch (error) {
        console.error('Error fetching accounts:', error)
      }
    },
    async LISTAR_CONTRATOS({ commit, state }) {
      try {
        const response = await axios.get('/api/admin/contrato', {
          headers: { 'account-id': state.account_id || getUser.account_id[0].id }
        })
        commit('LISTAGEM_CONTRATOS', response.data.contracts)
      } catch (error) {
        console.error('Error fetching contracts:', error)
      }
    },
    async LISTAR_UNIDADES({ commit, state }) {
      try {
        const response = await axios.get('/api/admin/unidade', {
          headers: { 'Account-Id': state.account_id || getUser.account_id[0].id }
        })
        commit('LISTAGEM_UNIDADES', response.data.unidades)
      } catch (error) {
        console.error('Error fetching units:', error)
      }
    },
    async LISTAR_EMPRESAS({ commit, state }) {
      try {
        const response = await axios.get('/api/admin/empresa', {
          headers: { 'Account-Id': state.account_id || getUser.account_id[0].id }
        })
        commit('LISTAGEM_EMPRESAS', response.data.empresas)
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    }
  }
}
