<template>
  <!--Logo start-->
  <div class="logo-main">
    <div v-if="view" class="logo-mini">
      <!-- SVG LOGO MINI -->
      <img v-if="theme" src="@/assets/images/logo/1.2.png" class="logo-mini-branco" alt="Logo Adapts">
      <img v-else src="@/assets/images/logo/2.1.png" class="logo-mini-branco" alt="Logo Adapts">
    </div>
    <div v-else class="logo-normal">
      <img v-if="theme" src="@/assets/images/logo/1.png" class="logo-branco" alt="Logo Adapts">
      <img v-else src="@/assets/images/logo/2.png" class="logo-branco" alt="Logo Adapts">
    </div>
  </div>
  <!--Logo End-->
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({ color: { type: Boolean, default: false } });
const store = useStore();
const view = ref(false);
const theme = ref(false);
// Acessar o state de setting dentro do namespace 'settings'
const appSettings = computed(() => store.state.setting.setting);
// Exemplo de acesso ao valor específico sidebar_type
const sidebarType = computed(() => appSettings.value.sidebar_type.value[0]);
const themeType = computed(() => appSettings.value.theme_scheme.value)

watch(sidebarType, () => {
  if (sidebarType.value === "sidebar-mini") {
    view.value = true;
  } else {
    view.value = false;
  }
})
watch(themeType, () => {
  if (themeType.value === "dark") {
    theme.value = true;
  } else {
    theme.value = false;
  }
})

</script>


<style scoped>
.logo-normal > img{
  width: 140px;
}
.logo-mini > img{
  width: 40px;
}
</style>