// Register global components
export default {
  install: (app) => {
    // Brand Components
    app.component('brand-logo', require('@/components/custom/logo/BrandLogo').default)
    app.component('brand-name', require('@/components/custom/logo/BrandName').default)
    // Icon Components
    app.component('icon-component', require('@/components/icons/IconComponent').default)
    // Select Component
    app.component('select-component', require('@/components/custom/select/SelectComponent').default)
  }
}
