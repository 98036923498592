import axios from 'axios'
import { getCookie } from '@/utilities/cookieTools'
import { getUser } from '@/utilities/accessControl'

let instance

if (getUser && getUser.account_id && getUser.account_id[0] && getUser.account_id[0].id) {
  const account_id = getUser.account_id[0].id
  instance = createAxiosInstance(account_id)
} else {
  console.error('Failed to get user account ID. Redirecting...')
  window.location.href = `${process.env.VUE_APP_DIRECT}?redirect=control`
}

function createAxiosInstance(accountId) {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Accept: 'application/json',
      'X-Session-ID': getCookie('session_id') ? getCookie('session_id') : null,
      'Account-id': accountId ? accountId : null
    }
  })
}

// Add an interceptor to dynamically update the authorization header
if (instance) {
  instance.interceptors.request.use(
    (config) => {
      const token = getCookie('token')
      if (token != null) {
        config.headers.Authorization = token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export function updateAxiosInstance(accountId) {
  instance = createAxiosInstance(accountId)
}

export default instance
