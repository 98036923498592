import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Connect API
import VueAxios from 'vue-axios'
import axios from './utilities/axios'

// Library Components
import BootstrapVue3 from 'bootstrap-vue-3'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue3-apexcharts'
import CounterUp from 'vue3-autocounter'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
require('waypoints/lib/noframework.waypoints.min')

const app = createApp(App)

app.use(store)
app.use(router)
app.use(i18n)

// Library Components
app.use(VueSweetalert2);
window.Swal =  app.config.globalProperties.$swal;

app.use(VueApexCharts)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

// ACCESS CONTROL
import { getUser, findPermissions } from './utilities/accessControl';
app.provide('user', getUser );
app.provide('findPermissions', findPermissions);

// Axios Connection
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)



app.mount('#app')

export default app
