<template>
  <vue-select v-model="newValue" :options="options" :label="label" :track-by="trackBy" :searchable="searchable"
    :placeholder="placeholder" :native="native" @open="handleOpen" @clear="handleClear">
    <slot></slot>
  </vue-select>
</template>

<script setup>
import { ref, watch } from 'vue';
import VueSelect from '@vueform/multiselect';

// Props definidas para serem passadas para o componente
const props = defineProps({
  modelValue: {
    type: [String, Object, Number, null],
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select',
  },
  options: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: 'name',
  },
  trackBy: {
    type: String,
    default: 'name',
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  native: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// Emitir eventos para o componente pai
const emit = defineEmits(['update:modelValue', 'select', 'open', 'clear']);

const newValue = ref(props.modelValue);

watch(() => props.modelValue, (newVal) => {
  newValue.value = newVal;
});

watch(newValue, () => {
  emit('update:modelValue', newValue.value);
  if (newValue.value === null) {
    emit('clear');
  }
});

// Funções para lidar com eventos de seleção, abertura e limpeza
function handleOpen(select$) {
  emit('open', select$);
}

function handleClear(select$) {
  emit('clear', select$);
}
</script>

<style>
@import '@vueform/multiselect/themes/default.css';

/* Estilizações personalizadas */
.multiselect-option.is-selected {
  background: var(--bs-primary) !important;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--bs-primary) !important;
}

.multiselect-option.is-disabled {
  background: var(--bs-secondary) !important;
}

.multiselect.is-active {
  box-shadow: unset !important;
}

.multiselect.is-open {
  border-radius: unset;
}

.multiselect-option.is-pointed {
  background: var(--bs-primary);
  color: white;
}

.multiselect-tag {
  background: var(--bs-primary);
}

.multiselect-tag-remove-icon {
  color: white !important;
}

.multiselect-wrapper {
  min-height: 0px;
}

.multiselect-search {
  color: var(--bs-primary);
}

.multiselect.is-disabled {
  background: #333;
}

.multiselect {
  display: flex;
  height: 20px;
  width: 12rem;
  font-size: 13px;
}
</style>
