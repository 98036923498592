import { getCookie } from '@/utilities/cookieTools'

export const getUser = {
  id: getCookie('user_id'),
  account_id: JSON.parse(getCookie('account_id')),
  isMaster: getCookie('d98b190ec15452a2e7d9f07f0495a676'),
  permissions: JSON.parse(getCookie('permissions'))
}
export const findPermissions = (objeto, accountId, modulo, permission) => {
  if (objeto != null && objeto.hasOwnProperty(accountId)) {
    const modulos = objeto[accountId]
    for (let i = 0; i < modulos.length; i++) {
      const permissoesModulo = modulos[i][modulo] // Acessa as permissões do módulo
      if (permissoesModulo) {
        for (let j = 0; j < permissoesModulo.length; j++) {
          if (permissoesModulo[j].permission_name === permission) {
            return true
          }
        }
      }
    }
  }
  return false
}
